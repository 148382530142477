// @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;600;700&display=swap');

@font-face {
    font-family: 'Poppins';
    src: url('/i/fonts/Poppins-Regular.ttf');
    font-weight: 400;
}

@font-face {
    font-family: 'Poppins';
    src: url('/i/fonts/Poppins-SemiBold.ttf');
    font-weight: 600;
}

@font-face {
    font-family: 'Poppins';
    src: url('/i/fonts/Poppins-Bold.ttf');
    font-weight: 700;
}

@import 'reset.scss';
@import 'variables.scss';
@import 'header.scss';
@import 'footer.scss';
@import 'common.scss';
@import 'legal.scss';
@import 'cookies-warning.scss';

html {
    height: -webkit-fill-available;
}

body {   
    height: -webkit-fill-available;
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    font-size: 16px;
    line-height: 26px;

    color: $ColorText;
}

#root {
	min-height: 100vh;
	min-height: -webkit-fill-available;

	display: grid;
	grid-template-rows: max-content auto max-content;
}

div.body {
    padding: 24px 0;
    @media screen and (min-width: $BreakpointSM) {
        padding: 32px 0;
    }
}

h1 {
    color: $ColorDark;
    font-weight: 700;
    font-size: 48px;
    line-height: 55px;
    margin-bottom: 20px;
}

h2 {
    color: $ColorDark;

    font-weight: 700;
    font-size: 40px;
    line-height: 55px;
    margin-bottom: 20px;
}

p {
    margin-bottom: 40px;
}

a {
    cursor: pointer;
}

button {
    padding: 13px 21px;
    border-radius: 35px;

    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    @media screen and (min-width: $BreakpointSM) {
        padding: 20px 45px;
        font-size: 20px;
        line-height: 30px;
        min-width: 270px;
    }
    text-align: center;
    text-transform: uppercase;

    background-color: $ColorDark;
    color: white;
    border: 1px solid #FFFFFF;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

    cursor: pointer;

    &.-gradient {
        border: 1px solid black;
        background: linear-gradient(291.14deg, #F02A08 13.41%, #30006E 92.09%);
    }

    &.-wide {
        width: 100%;
    }

    &.-disabled {
        background-color: #9F9B9A;
        cursor: default;
    }

    &.-half-wide {
        width: 100%;
        @media screen and (min-width: $BreakpointSM) {
            max-width: 50%;
        }
    }

    &.-discord {
        svg {
            margin-right: 12px;
            position: relative;
            top: 4px;
        }
    }

}

.layout-container {
    width: 100%;
    max-width: calc($BodyMaxWidth + 32px * 2);
    margin: 0 auto;
}

section {
    width: 100%;
    max-width: $BodyMaxWidth;

    margin: 0 auto 120px auto;
    padding: 0 24px;
    @media screen and (min-width: $BreakpointSM) {
        padding: 0 32px;
    }

    &:last-of-type {
        margin-bottom: 64px;
    }

    &.-collection-description {
        .title {
            padding: 32px 0;
            border-bottom: 1px solid $ColorDark;
            margin-bottom: 32px;
        }
        h3 {
            font-weight: 700;
            font-size: 20px;
            line-height: 28px;

            display: inline-block;
            
            background: linear-gradient(291.14deg, #F02A08 13.41%, #30006E 92.09%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            background-clip: text;
            text-fill-color: transparent;
        }
    }
}
