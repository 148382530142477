.legal {

    margin-bottom: 64px;

    p {
        margin-bottom: 1rem;
    }
    
    ul {
        padding-left: 2rem;
        li {
            margin-bottom: 1rem;
        }
    }

    ol {
        position: relative;
        counter-reset: section;
        list-style-type: none;
        & > li {
            display: block;
            &:before {
                position: absolute;
                left: 0;
                content: counters(section, ".") ". ";
                counter-increment: section;
            }
            margin-bottom: 1rem;
            padding-left: 2rem;
        }

        &.-a {
            li {
                &:before {
                    content: "(" counter(section, lower-alpha) ") ";
                }
            }
        }

        &.-a.-clean {
            li {
                &:before {
                    content: counter(section, lower-alpha) ". ";
                }
            }
        }

        &.-i {
            li {
                &:before {
                    content: "(" counter(section, lower-roman) ") ";
                }
            }
        }
    }

    & > ol > li {
        // padding-left: 3.5rem;
        margin-bottom: 3rem;
    }

    & > ol > li:before, h2 {
        display: inline-block;
        font-weight: 700;
        font-size: 24px;
        line-height: 28px;
        margin: 0;
    }

    blockquote {
        margin: 0;
        padding: 0 1rem;
        text-indent: 0;
    }

    .-bordered {
        border: 2px solid black;
        padding: 2rem;
    }

    table {
        border-spacing: 0;
        td, th {
            padding: 1rem 1rem 0.5rem 0;
            border-bottom: 2px solid black;
        }
        th {
            text-align: left;
        }

        tr:last-of-type {
            td {
                border: 0;
            }
        }

        &.clean {
            margin-bottom: 2rem;
            td, th {
                padding: 0 1rem 0 0;
                border-bottom: none;
            }
        }
    }
}