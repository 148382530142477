$BreakpointSM:	640px;
$BreakpointMD:	1000px;
$BreakpointLG:	1276px;
$BreakpointXL:	1540px;

$BodyMaxWidth:	1170px;

$ColorDark:		#150B22;
$ColorText:		#473F51;

$ColorDT:		#30006E;
$ColorBorder:   #383041;