@import 'variables.scss';

footer {
    margin-top: 16px;
    
    background-color: black;
    color: white;

    font-weight: 500;
    font-size: 16px;
    line-height: 22px;

    a {
        color: white;
        text-decoration: none;
    }

    ul {
        li {
            display: inline-block;
        }
    }

    h3 {
        font-weight: 600;
        font-size: 22px;
        line-height: 30px;
        margin-bottom: 24px;
    }


    &>div:first-child {
        display: grid;

        grid-template-columns: 1fr;
        grid-template-areas:    'updates'
                                'menu';

        text-align: center;

        @media screen and (min-width: $BreakpointSM) {
            grid-template-columns: auto auto;
            grid-template-areas:    'updates menu';
            text-align: left;
        }

        padding-top: 32px;
        padding-bottom: 32px;

        .updates {
            grid-area: updates;

            form {
                display: inline-flex;
                background-color: white;
                border-radius: 52px;
                padding: 6px;
                height: calc(56px + 6px * 2);

                input {
                    margin-left: 42px;
                }

                button {
                    min-width: 120px;
                    color: white;
                    background: linear-gradient(291.14deg, #F02A08 13.41%, #30006E 92.09%);
                    border-radius: 43px;
                    padding: 16px 38px;
                    text-transform: uppercase;
                    font-weight: 600;
                    font-size: 16px;
                    line-height: 24px;
                }
            }

            .feedback {
                padding: 16px;
                align-items: center;
                height: calc(56px + 6px * 2);
            }
        }

        .menu {
            grid-area: menu;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            margin-top: 32px;
            gap: 32px;


            @media screen and (min-width: $BreakpointMD) {
                margin-top: 0;
                gap: 0;
                align-items: flex-end;
            }
        }

    }

    &>div:nth-child(2) {
        @media screen and (min-width: $BreakpointSM) {
            border-top: 1px solid #383041;
        }

        color: #B6B6B6;

        a {
            color: #B6B6B6;
            .icon {
                fill: white;
            }
        }

        &>div {
            padding-top: 12px;
            padding-bottom: 12px;
            display: grid;

            font-size: 14px;

            grid-template-columns: 1fr;
            grid-template-areas:    "social"
                                    "sublinks"
                                    "copyright";
            text-align: center;

            @media screen and (min-width: $BreakpointSM) {
                grid-template-columns: max-content auto min-content;
                grid-template-areas: "copyright sublinks social";
                gap: 36px;
                text-align: left;
            }

            .copyright {
                grid-area: copyright;
                margin: 10px 0;
            }

            .sublinks {
                grid-area: sublinks;
                display: flex;
                flex-direction: column;
                gap: 16px;
                margin: 32px 0;
                @media screen and (min-width: $BreakpointSM) {
                    margin: 10px 0;
                    flex-direction: row;
                    gap: 36px;
                }
            }

            .social {
                grid-area: social;
                display: flex;
                gap: 18px;
                justify-content: center;
            }
        }
    }
}
