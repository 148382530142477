@import 'variables.scss';

header {
    z-index: 1000;
    padding-top: 16px;
    padding-bottom: 16px;

    // background-color: white;
    color: $ColorDark;

    font-size: 18px;
    line-height: 22px;

    & > div {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-areas: 'logo menu';
    }

    a {
        color: $ColorDark;
        text-decoration: none;
    }

    .logo {
        grid-area: logo;
        height: 41px;
        svg {
            width: 148px;
            height: 41px;
            fill: $ColorDark;
        }
    }

    .menu {
        grid-area: menu;

        display: none;
        justify-content: flex-end;

        @media screen and (min-width: $BreakpointSM) {
            display: flex;
        }

        li {
            display: inline-block;
            margin: 9px 0;
            @media screen and (min-width: $BreakpointSM) {
                &:not(:last-child) {
                    margin-right: 42px;
                }
            }

            white-space: nowrap;

            &.umbro {
                font-weight: 600;
            }

            &.collection {
                position: relative;
                margin-left: 44px;
                &::before {
                    content: ' ';
                    position: absolute;
                    width: 35px;
                    height: 44px;
                    background-image: url('/i/i-collection.svg');
                    background-size: 35px 44px;
                    top: -12px;
                    left: -44px;
                    z-index: 100;
                }
            }
        }

        

        @media screen and (max-width: calc($BreakpointSM - 1px)) {
            position: absolute;
            top: 73px;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: white;

            padding: 32px;
            align-items: center;
            justify-content: center;

            li {
                margin: 21px 0;
            }
        }
    }

    input {
        display: none;

        &:checked ~ .menu {
            display: flex;
            flex-direction: column;
        }

        &:checked ~ label {
            background-image: url('/i/burger-close.svg');
        }
    }
    label {
        grid-area: menu;
        transition: all 200ms ease-in-out;
        
        @media screen and (min-width: $BreakpointSM) {
            display: none;
        }

        height: 32px;
        width: 32px;
        min-width: 32px;

        margin: calc((41px - 32px) / 2) 0;

        justify-self: end;

        background-image: url('/i/burger.svg');
        background-size: 32px 32px;
        background-position: center;
        background-repeat: no-repeat;
    }

}
