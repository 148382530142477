@import '../../styles/variables.scss';

.home-community {
    display: grid;
    gap: 32px;
    grid-template-columns: 1fr;
    grid-template-areas:    'image'
                            'text'; 

    @media screen and (min-width: $BreakpointSM) {
        grid-template-columns: 1fr 1fr;
        grid-template-areas: 'text image'; 
    }

    .text {
        grid-area: text;
    }

    .image {
        min-height: 260px;
        grid-area: image;
        background-size: cover;
        background-position: center center;
        background-image: url('/i/home/community.jpg');
        border: 5px solid #FFFFFF;
        border-radius: 35px;
        filter: drop-shadow(35px 1px 94px rgba(157, 25, 53, 0.15));
    }
}


.countdown {
    & > div {
        margin-top: 12px;
        display: flex;
        gap: 8px;

        & > div {
            width: 48px;
        }

        h4 {
            font-size: 24px;
            line-height: 12px;
        }

        span {
            font-size: 12px;
            line-height: 12px;
        }
    }
}

.countdown-block {
    display: flex;
    gap: 32px;
    flex-direction: column;

    @media screen and (min-width: $BreakpointSM) {
        flex-direction: row;
        align-items: flex-end;
    }

    button {
        border-color: black;
    }
}
