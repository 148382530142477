@import '../../styles/variables.scss';

section.user {
    padding-bottom: 40px;
    border-bottom: 1px solid $ColorBorder;

    display: grid;
    column-gap: 40px;
    align-items: center;
    grid-template-columns: 1fr;
    grid-template-areas:    'image'
                            'name'
                            'email'
                            'button';
    text-align: center;
    @media screen and (min-width: $BreakpointSM) {
        grid-template-columns: 180px auto min-content;
        grid-template-areas:    'image name button'
                                'image email button';
        text-align: left;
    }

    img {
        grid-area: image;
        margin: 0 auto;

        width: 180px;
        height: 180px;
        border: 4px solid #FFFFFF;
        border-radius: 180px;
        box-shadow: 35px 1px 94px rgba(157, 25, 53, 0.15);
    }

    h2 {
        grid-area: name;
        margin: 0;
        align-self: end;
        font-weight: 700;
        font-size: 32px;
        line-height: 44px;
    }

    div.email {
        padding-top: 16px;
        grid-area: email;
        align-self: start;
        font-weight: 700;
        font-size: 16px;
    }

    div.edit {
        grid-area: button;
        @media screen and (max-width: calc($BreakpointSM - 1px)) {
            margin-top: 32px;
        }
    }
}


section.user-collection {
    & > div {
        display: grid;
        gap: 32px;
        grid-auto-rows: auto;
        grid-template-columns: 1fr;
        @media screen and (min-width: $BreakpointSM) {
            grid-template-columns: 1fr 1fr;
        }
        @media screen and (min-width: $BreakpointMD) {
            grid-template-columns: 1fr 1fr 1fr;
        }
    }

    .nft {
        background-color: #FFFFFF;
        box-shadow: 35px 1px 94px rgba(157, 25, 53, 0.15);
        border-radius: 35px;

        padding: 30px 32px 24px 32px;

        position: relative;

        .collection {
            margin: 0 0 8px 12px;

            display: grid;
            column-gap: 8px;
            grid-template-columns: 32px auto;
            grid-template-areas:    'image label'
                                    'image collection';
            img {
                grid-area: image;
                width: 32px;
                height: 32px;

                border: 1px solid black;
                border-radius: 35px;
            }

            h3 {
                grid-area: label;
                font-weight: 700;
                font-size: 10px;
                line-height: 14px;
            }

            h4 {
                grid-area: collection;
                font-weight: 500;
                font-size: 10px;
                line-height: 14px;
            }
        }

        .asset {
            img {
                width: 100%;
                border: 5px solid #FFFFFF;
                border-radius: 35px;
            }

            & > div {
                // border: 5px solid #FFFFFF;
                border-radius: 35px;
                max-width: 100%;
                overflow: hidden;
            }

            video {
                max-width: 100%;
            }

            h3 {
                font-weight: 700;
                font-size: 14px;
                line-height: 22px;
                margin: 12px 0 12px 12px;
            }

            & > p {
                margin: 0 0 8px 0;
                padding: 0 16px;
                font-weight: 400;
                font-size: 14px;
                line-height: 22px;

                display: flex;
                align-items: center;

                p {
                    margin: 0;
                }
            }
        }

        .actions {

            display: grid;
            grid-template-columns: auto min-content;
            gap: 16px;

            button {
                min-width: 100%;
                width: 100%;
                height: 54px;
                padding: 12px 20px;
                
                font-size: 14px;

                border: none;
                box-shadow: none;

                &.-twitter {
                    width: 54px;
                    padding: 12px;

                    svg {
                        position: relative;
                        top: 3px;
                    }
                }
            }
        }
    }
}