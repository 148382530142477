@import 'variables.scss';

.common--umbro-nations {

    h1, h2 {
        font-weight: 700;
        font-size: 40px;
        line-height: 55px;
        margin-bottom: 20px;
    }

    display: grid;
    gap: 32px;
    align-items: center;
    grid-template-columns: 1fr;
    @media screen and (min-width: $BreakpointSM) {
        grid-template-columns: 1fr 1fr; 
    }

    .image {
        img {
            width: 100%;
            filter: drop-shadow(35px 24px 44px rgba(157, 25, 53, 0.30));
        }
    }

    .buttons {
        display: grid;
        gap: 12px;
        grid-template-columns: 1fr 1fr;
        button {
            width: 100%;
        }

        @media screen and (min-width: $BreakpointSM) {
            display: flex;
            gap: 20px;
        }
    }
}

.bayan-item {
	
	border-top: 1px solid #383041;
	padding: 16px 0;

	input {
		display: none;

		&:checked ~ div {
			display: block;
		}

		&:checked ~ label:after {
			transform: rotate(180deg);
		}
	}
	label {
		position: relative;

		display: inline-block;

		width: 100%;
		padding-right: 48px;
		margin-bottom: 8px;

		color: $ColorDT;
        font-weight: 600;
        font-size: 20px;
        line-height: 32px;

		&:after {
			position: absolute;
			top: 4px;
			right: 0;

			transition: all 300ms ease-in-out;

			content: " ";
			background-image: url("/i/bayan-icon.svg");
			background-size: 24px 24px;
			width: 24px;
			height: 24px;
			min-width: 24px;
			min-height: 24px;
		}
	}

	div {
		display: none;
		padding-right: 48px;
	}
}


.common-hero {
    margin: 0 0 120px 0;
    background: linear-gradient(102.54deg, #2F0070 0.66%, #9C1835 48.05%, #FF2E00 71.41%, #170303 101.32%);

    section {
        display: grid;
        gap: 32px;
        grid-template-columns: 1fr;
        @media screen and (min-width: $BreakpointSM) {
            grid-template-columns: 1fr 1fr; 
        }

        .text {
            padding: 32px 0 0 0;
            @media screen and (min-width: $BreakpointSM) {
                padding: 80px 0;
            }
        }

        .image {
            background-image: url('/i/home/hero.png');
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center;

            margin-bottom: 32px;

            @media screen and (max-width: calc($BreakpointSM - 1px)) {
                background-image: url('/i/home/hero@m.png');
                height: 256px;
            }
        }

        color: white;

        h1, h2 {
            color: white;
        }
    }
}

.nft-block {
	background: white;
    box-shadow: 35px 1px 94px rgba(157, 25, 53, 0.15);
    border-radius: 35px;

    padding: 20px;
	margin-bottom: 16px;

	&:first-of-type {
		margin-top: 32px;
	}

	display: grid;
    column-gap: 24px;
	grid-template-columns: 88px auto;
	grid-template-rows: min-content min-content;
	grid-template-areas:    'image title'
							'image description';

    img {
        grid-area: image;
        width: 88px;
        height: 88px;
        border-radius: 88px;
    }

    h3 {
        grid-area: title;
        align-self: end;
    }

    p {
        grid-area: description;
        align-self: start;
        margin: 0;
    }

	&.–collection {
		img {
			border: 1px solid black;
		}
	}

    & > div {
        overflow: auto;
    }

	&.-owned-by {
		.owner {
            font-size: 90%;
            overflow-wrap: break-word;
		}
		.transaction {
			font-size: 10px;
			line-height: 12px;
		}
	}
}
