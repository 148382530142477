@import '../../styles/variables.scss';

section.edit-profile {
    display: grid;
    grid-template-columns: 1fr;
    @media screen and (min-width: $BreakpointSM) {
        grid-template-columns: 2fr 3fr;
        gap: 64px;
    }
    align-items: start;
    .user {
        display: grid;
        column-gap: 30px;
        align-items: center;
        margin-bottom: 40px;

        grid-template-columns: 1fr;
        grid-template-areas:    'image'
                                'name'
                                'email'
                                'info'
                                'link';
        text-align: center;

        @media screen and (min-width: $BreakpointSM) {
            grid-template-columns: 140px auto;
            grid-template-areas:    'image name'
                                    'image email'
                                    'info info'
                                    'link link';
            text-align: left;
        }
        
        img {
            grid-area: image;

            margin: 0 auto;
    
            width: 140px;
            height: 140px;
            border: 4px solid #FFFFFF;
            border-radius: 140px;
            box-shadow: 35px 1px 94px rgba(157, 25, 53, 0.15);
        }

        h2 {
            grid-area: name;
            margin: 0;
            align-self: end;
            font-weight: 700;
            font-size: 24px;
            @media screen and (min-width: $BreakpointSM) {
                font-size: 32px;
            }
            line-height: 44px;
        }
    
        div.email {
            padding-top: 16px;
            grid-area: email;
            align-self: start;
            font-weight: 700;
            font-size: 16px;
        }

        div.info {
            grid-area: info;
            margin-top: 32px;
            padding: 32px 0;
            border-top: 1px solid $ColorDark;

            text-align: left;

            h3 {
                margin-bottom: 8px;
            }

            p {
                margin-bottom: 0;
            }
        }

        div.link {
            grid-area: link;
            padding: 32px 0;
            border-top: 1px solid $ColorDark;
        }
    }

    form {
        background: white;
        box-shadow: 35px 1px 94px rgba(157, 25, 53, 0.15);
        border-radius: 35px;

        padding: 40px;

        h1 {
            margin-bottom: 40px;

            font-weight: 600;
            font-size: 20px;
            line-height: 30px;
            text-transform: uppercase;
        }

        h2 {
            font-weight: 700;
            font-size: 24px;
            line-height: 33px;
        }

        & > div {
            margin-bottom: 40px;
        }

        .avatars {
            display: flex;
            gap: 20px;
            flex-wrap: wrap;
            img {
                width: 40px;
                height: 40px;
                border-radius: 40px;
                cursor: pointer;
                &.current {
                    border: 4px solid white;
                    box-shadow: 0px 2px 24px rgba(157, 25, 53, 0.32);
                }
            }
        }

        .info {

            display: grid;
            gap: 16px;
            grid-template-columns: 1fr;
            grid-template-areas:    'firstname'
                                    'lastname'
                                    'email';
            @media screen and (min-width: $BreakpointSM) {
                grid-template-columns: 1fr 1fr;
                grid-template-areas:    'firstname lastname'
                                        'email email';
            }

            & > div {
                border: 1px solid #A299AD;
                border-radius: 52px;
                padding: 14px 30px;

                label {
                    display: block;
                    font-weight: 500;
                    font-size: 12px;
                    line-height: 18px;
                    text-transform: uppercase;
                    color: #88838E;
                }

                input {
                    width: 100%;
                    font-weight: 500;
                    font-size: 16px;
                    line-height: 24px;

                    &:disabled {
                        color: #88838E;
                    }
                }
            }

            .firstname {
                grid-area: firstname;
            }

            .lastname {
                grid-area: lastname;
            }

            .email {
                grid-area: email;
            }
        }

        .marketing {
            input {
                margin-right: 16px;
            }
        }

        .buttons {
            display: grid;
            gap: 16px;
            grid-template-columns: 1fr;
            @media screen and (min-width: $BreakpointSM) {
                grid-template-columns: 1fr 1fr;
            }

            button {
                width: 100%;

                &.cancel {
                    background-color: white;
                    color: $ColorDark;
                    border: 1px solid $ColorDark;
                    border-radius: 35px;
                }
            }
        }
    }
}

