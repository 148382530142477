@import '../../styles/variables.scss';

.collection-nft {
    display: grid;
    
    grid-template-columns: 1fr;

    @media screen and (min-width: $BreakpointSM) {
        grid-template-columns: minmax(100px, 470px) minmax(400px, 1fr);
        gap: 100px;
    }

    .image {
        width: 100%;
        & > div {
            filter: drop-shadow(35px 1px 94px rgba(157, 25, 53, 0.15));
            border: 5px solid white;
            border-radius: 35px;
            background-color: white;
        }
        img, video {
            width: 100%;
        }
    }

    .info {
        padding-top: 32px;
        h1 {
            margin-bottom: 32px;

            font-weight: 700;
            font-size: 32px;
            line-height: 44px;
        }
    }

    
}



h3.-gradient {
    display: inline-block;
    background: linear-gradient(291.14deg, #F02A08 13.41%, #30006E 92.09%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
}