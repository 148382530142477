@import 'variables.scss';

.cookies-warning {
    background: linear-gradient(102.54deg, #2F0070 0.66%, #9C1835 48.05%, #FF2E00 71.41%, #170303 101.32%);
    box-shadow: 0px 10px 85px rgba(186, 30, 36, 0.21);
    border-radius: 43px;

    width: 50%;
    max-width: calc(100vw - 64px);
    position: fixed;
    bottom: 32px;
    left: 32px;

    z-index: 65535;

    padding: 32px 48px;

    color: white;

    a {
        color: white;
    }
}