@import '../../styles/variables.scss';

.about-hero {
    display: grid;
    gap: 32px;
    grid-template-columns: 1fr;
    padding: 0 0 32px 0;
    @media screen and (min-width: $BreakpointSM) {
        grid-template-columns: 3fr 2fr; 
    }


    .text {
        padding: 32px 24px 0 24px;
        @media screen and (min-width: $BreakpointSM) {
            padding: 80px 0 48px 80px;
        }
    }

    .image {
        background-image: url('/i/about/hero.png');
        background-size: cover;
        @media screen and (max-width: calc($BreakpointSM - 1px)) {
            background-image: url('/i/about/hero@m.png');
            height: 256px;
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center;
        }
    }

    background: linear-gradient(102.54deg, #2F0070 0.66%, #9C1835 48.05%, #FF2E00 71.41%, #170303 101.32%);
    box-shadow: 0px 20px 45px rgba(186, 30, 36, 0.21);
    border-radius: 43px;

    color: white;

    h1, h2 {
        color: white;
    }

    button {
        background-color: white;
        color: $ColorDark;

        width: 100%;
        max-width: 390px;

    }

}

.roadmap {
    text-align: center;
    position: relative;
    padding: 0;
    .image {
        &:before,
        &:after {
            position: absolute;
            top: 0;
            content: ' ';
            background-repeat: repeat-y;
            width: 32px;
            height: 100%;
        }
        &:before {
            left: 0;
            background-image: url('/i/rm-l.png');
        }
        &:after {
            right: 0;
            background-image: url('/i/rm-r.png');
        }
    }
    @media screen and (max-width: calc($BreakpointSM - 1px)) {
        .image {
            max-width: 100vw;
            overflow: scroll;

            img {
                width: 1200px;
            }
        }
    }
}

.benefits {
    text-align: center;

    .cards {
        display: grid;
        gap: 32px;
        @media screen and (min-width: $BreakpointSM) {
            grid-template-columns: 1fr 1fr 1fr;
            grid-auto-rows: auto;
        }

        .card {
            background: #FFFFFF;
            box-shadow: 35px 1px 94px rgba(157, 25, 53, 0.15);
            border-radius: 35px;

            padding: 32px 40px;

            text-align: center;

            h3 {
                margin: 24px 0 8px 0;
            }

            p {
                margin: 0;
            }
        }
    }
}