@import '../../styles/variables.scss';

@media screen and (max-width: calc($BreakpointSM - 1px )) {
    .carousel {
        display: flex;
        gap: 12px;

        width: calc(100vw - 48px);
        overflow: scroll;
        scroll-snap-type: x mandatory;
        scroll-padding: 0 24px;

        .card {
            min-width: calc(100vw - 48px - 48px);
            scroll-snap-align: start;
        }

        button {
            display: none;
        }

        img {
            border-radius: 32px;
            width: 100%;
            max-width: 100%;
        }
    }
}

@media screen and (min-width: $BreakpointSM) {
    .carousel {
        // width: 380px;
        // min-height: 500px;
        width: 200px;
        min-height: 300px;
        max-height: 380px;
        margin: 0 auto;

        perspective: 400px;
        transform-style: preserve-3d;

        display: flex;
        align-items: center;

        button {
            background-color: rgba(0,0,0,0.2);
            color: white;
            font-size: 18px;
            position: absolute;
            top: calc(264px / 2);
            display: flex;
            align-items: center;
            justify-content: center;
            z-index: 2;
            cursor: pointer;
            user-select: none;
            width: 32px;
            min-width: 32px;
            height: 32px;
            padding: 0;


            &:hover {
                background-color: rgba(0,0,0,0.4);
            }
            
            &.left {
                transform: translateX(-270%) translateY(-50%);
            }
            
            &.right {
                right: 0;
                transform: translateX(270%) translateY(-50%);
            }
        }
    }

    .card-container {
        position: absolute;
        width: 100%;
        height: 100%;
        transform: 
            rotateY(calc(var(--offset) * 50deg)) 
            scaleY(calc(1 + var(--abs-offset) * -0.4))
            translateZ(calc(var(--abs-offset) * -30rem))
            translateX(calc(var(--direction) * -5rem));
            filter: blur(calc(var(--abs-offset) * 8px));
        transition: all 0.3s ease-out;
    }

    .card {
        width: 100%;
        height: 100%;
        transition: all 0.3s ease-out;

        img {
            border-radius: 32px;
            width: 100%;
            max-width: 100%;
            filter: drop-shadow(0px 12px 12px rgba(0, 0, 0, 0.3));
            border: 6px solid white;
        }
    }
}

@media screen and (min-width: $BreakpointMD) {
    .carousel {
        width: 70%;
        min-height: 465px;
        button {
            top: calc(465px / 2);
        }
    }
}