@import '../../styles/variables.scss';

.card-payment {
    padding: 32px 0;

    display: grid;
    gap: 16px;
    grid-template-columns: repeat(6, 1fr);
    grid-template-areas:    'number number number number number number'
                            'holder holder holder holder holder holder'
                            'month month year year cvc cvc'
                            'email email email email email email'
                            'address1 address1 address1 address1 address1 address1'
                            'address2 address2 address2 address2 address2 address2'
                            'city city city state state state'
                            'zip zip zip country country country'
                            'terms terms terms terms terms terms'
                            'submit submit submit submit submit submit';

    input:not([type='checkbox']) {
        width: 100%;
        height: 32px;
        font-size: 20px;

        &::placeholder {
            opacity: 0;
        }
    }

    .input {
        border: 1px solid #A299AD;
        background-color: #FFFFFF;
        padding: 8px 24px;
        width: 100%;
        border-radius: 52px;
        height: 70px;

        &.-error {
            border: 1px solid #cc0000;
        }

        label {
            font-size: 12px;
            line-height: 18px;
            text-transform: uppercase;
            color: #88838E;
        }

        input {
            position: relative;
            top: -4px;
        }
    }

    .number {
        grid-area: number;
    }

    .holder {
        grid-area: holder;
    }

    .month {
        grid-area: month;
    }

    .year {
        grid-area: year;
    }

    .cvc {
        grid-area: cvc;
    }

    .terms {
        grid-area: terms;
        margin-top: 16px;
        &.-warning {
            color: #cc0000;
        }
    }

    .submit {
        grid-area: submit;
    }

    .email {
        margin: 32px 0;
        grid-area: email;
    }

    .address {
        &.-a1 {
            grid-area: address1;
        }

        &.-a2 {
            grid-area: address2;
        }
    }

    .city {
        grid-area: city;
    }

    .state {
        grid-area: state;
    }

    .zip {
        grid-area: zip;
    }

    .country {
        grid-area: country;
    }

}

.sold-out {
    margin-top: 32px;
    button {
        background-color: #9F9B9A;
    }
}
