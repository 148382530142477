@import '../../styles/variables.scss';

.collection-nft.-buy {

    @media screen and (max-width: calc($BreakpointSM - 1px)) {
        .image {
            text-align: center;
            img {
                width: 50%;
            }
        }
    }
}